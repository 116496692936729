import React from "react";
import { graphql } from "gatsby";
import rehypeReact from "rehype-react";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Colors from "../constants/Colors";
import SpacingVertical from "../components/SpacingVertical";
import CallToAction from "../components/CallToAction";

const renderAst = new rehypeReact({
  createElement: React.createElement,
}).Compiler;

const BlogPost = (props) => {
  const { data } = props;
  const markdownRemark = data.markdownRemark;
  const { excerpt, htmlAst, frontmatter, fields } = markdownRemark;
  const { slug } = fields;
  const { title, date, ogImage } = frontmatter;
  const ogImagePath = ogImage && ogImage.childImageSharp.fixed.src;

  return (
    <Layout override="marginStandard">
      <SEO title={title} description={excerpt} image={ogImagePath} url={slug} />
      <SpacingVertical rems={4} />
      <div className="marginFocus">
        <h1>{title}</h1>
        <div style={styles.authorContainer}>
          <div>
            <p
              style={{
                ...styles.spaced,
                marginBottom: "0px",
              }}
            >
              {date}
            </p>
          </div>
        </div>
        <div style={styles.bodyText}>{renderAst(htmlAst)}</div>
      </div>
      <SpacingVertical rems={4} />
      <CallToAction
        backgroundColor={Colors.grayLight}
        inputColor={Colors.white}
        labelColor={Colors.blueDark}
      />
      <SpacingVertical rems={4} />
    </Layout>
  );
};

const styles = {
  authorContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    alignItems: "center",
    marginBottom: "1.0rem",
  },
  spaced: {
    color: Colors.gray2,
    fontSize: "0.8rem",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "1.0rem",
  },
  bodyText: {
    marginBottom: "3.0rem",
  },
};

export default BlogPost;

export const query = graphql`
  query PostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      excerpt
      frontmatter {
        title
        date(formatString: "MM.DD.YYYY")
        ogImage {
          childImageSharp {
            fixed {
              src
            }
          }
        }
      }
      fields {
        slug
      }
    }
  }
`;
