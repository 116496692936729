import React from "react";
import SpacingVertical from "./SpacingVertical";
import FormEmail from "./FormEmail";
import Strings from "../constants/Strings";
import FormName from "../constants/FormName";
import "./Layout.css";
import "./Shadow.css";

interface CallToActionProps {
  backgroundColor: string;
  labelColor: string;
  inputColor: string;
  title?: string;
  description?: string;
  formName?: string;
}

const CallToAction = (props: CallToActionProps) => {
  const {
    backgroundColor,
    labelColor,
    inputColor,
    title,
    description,
    formName = FormName.general,
  } = props;
  const computedStyle = { backgroundColor };
  return (
    <div className="marginStandard shadow" style={computedStyle}>
      <SpacingVertical rems={4} />
      <div className="marginFocus">
        <h2 style={styles.centerText}>{title || Strings.CallToAction.title}</h2>
        <p>{description || Strings.CallToAction.subtitle}</p>
        <SpacingVertical />
        <FormEmail
          labelColor={labelColor}
          inputColor={inputColor}
          buttonTitle={Strings.FormEmail.button}
          formName={formName}
        />
      </div>
      <SpacingVertical rems={4} />
    </div>
  );
};

const styles = {
  centerText: {
    textAlign: "center" as const,
  },
};

export default CallToAction;
